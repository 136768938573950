<template>
<div class="currency">
    <el-dialog :visible.sync="dialogFormVisible" >

    <el-form :inline="true" :model="selectedCurrency" label-width="80px">
      <el-form-item label="Country">
       <el-input v-model="selectedCurrency.country" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="Currency">
       <el-input v-model="selectedCurrency.currency" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    
        
    <div slot="footer">
      <el-button @click="dialogFormVisible = false">Cancel</el-button>
      <el-button type="primary" @click="Confirm">Confirm</el-button>
                   
    </div>
    </el-dialog>

<el-row>
<el-form :inline="true" :model="formInline" class="demo-form-inline">

  <el-form-item label="Country">
    <el-input v-model="formInline.country" placeholder="ex. Canada"></el-input>
  </el-form-item>

  <el-form-item label="Currnecy">
    <el-input v-model="formInline.currency" placeholder="ex. CAD"></el-input>
  </el-form-item>

  <el-form-item>
    <el-button type="primary" size="medium" plain round @click="add">Add</el-button>
  </el-form-item>

</el-form>
</el-row>

<el-row>
  <el-table
    border
    style="width:680px"
    :data="currencys">
    <el-table-column type="index" fixed></el-table-column>
    <el-table-column width= "200" label="Country" prop="country"></el-table-column>
    <el-table-column width= "200" label="Currency" prop="currency"></el-table-column>

    <el-table-column
      fixed="right">
      <template slot-scope="scope">
      <el-button @click="handleClick(scope.row)" type="success" size="medium" plain round>Modify</el-button>
      <el-button @click.native.prevent="deleteRow(scope.$index, currencys)"
      type="danger" size="medium" plain round>Delete</el-button>
      </template>
    </el-table-column>
        
  </el-table>
  </el-row>
      
</div>
</template>

<script>
import { axiosInstance } from '@/api'
export default {
 
  data() {
    return {
      currency: "",
      currencys: [],
      dialogFormVisible: false,
            
      selectedCurrency: {
        country:'',
        currency:''},

      formInline: {
        country:'',
        currency:''}
      
    }
  },

    created(){
        this.loadData()
    },

    methods: {

 
      add(){
        axiosInstance.post("/currency",this.formInline).then((response) => {
         this.loadData()
          })
    },

    loadData(){
          axiosInstance.get("/currency").then((response) => {
            this.currencys = response.data;
          })
    },

    handleClick(row) {
        this.selectedCurrency = row;
        this.dialogFormVisible =true;
        console.log(row);
      },
      
      Confirm(){
        axiosInstance.put("/currency/"+this.selectedCurrency.id,{country: this.selectedCurrency.country, currency: this.selectedCurrency.currency}).then((response) => {
           this.loadData()
        }).finally(()=>{
           this.dialogFormVisible =false;
        })
    },

      deleteRow(index, rows) {
        let row = rows[index];
        this.$confirm('Delete Or Not?', 'CHECK', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          axiosInstance.delete("/currency/"+row.id).then((response) => {
            rows.splice(index, 1);
            this.$message({
            type: "success",
            message: 'Success!!'
          });
          })
         
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Cancel'
          });          
        });
        
      },
  }
}

</script>

<style lang="scss" scoped>
.currency{
  .el-row {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }

}



</style>